export default class Language {
    id: number = +(new Date()) + Math.floor(Math.random() * 1000);
    constructor(
        public nameSentenceCase: string,
        public nameLowerCase: string,
        public color: string,
        public logoUri: string,
        public codeBlockSettings?: {
            text: string
            theme: string,
            language: string,
        },
    ) { }
}
