import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import classes from './DesktopNavigation.module.scss'
import navigation_data from '../../data/navigation-data'

const DesktopNavigation = () => {
    const location = useLocation();

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const updatePosition = () => {
            setScrollPosition(window.pageYOffset)
        }

        window.addEventListener('scroll', updatePosition)

        updatePosition()

        return () => window.removeEventListener('scroll', updatePosition)
    }, []);


    return (
        <nav className={`${classes.navBar} ${scrollPosition > 0 && classes.scrolled}`}>
            <ul className={classes.navList}>
                {navigation_data.map(item => (
                    <Link key={item.title} to={item.path}>
                        <li className={`${classes.navItem} ${location.pathname === item.path && classes.active}`}>{item.title}</li>
                    </Link>
                ))}
            </ul>
        </nav>
    )
}

export default DesktopNavigation;
