import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loader from './components/UI/Loader';
import Layout from './components/UI/Layout';
import Project from './pages/Project';
import {library} from "@fortawesome/fontawesome-svg-core";
import {faBars, faSquare, faThLarge} from "@fortawesome/free-solid-svg-icons";

library.add(faBars, faSquare, faThLarge);

const Home = React.lazy(() => import('./pages/Home'));
const Projects = React.lazy(() => import('./pages/Projects'));
// const Languages = React.lazy(() => import('./pages/Languages'));
const Error = React.lazy(() => import('./pages/Error'));

function App() {
	return (
		<Layout>
			<Suspense fallback={<Loader />}>
				<Switch>
					<Route path="/" exact>
						<Home />
					</Route>
					<Route path="/projects/:slug">
						<Project />
					</Route>
					<Route path="/projects">
						<Projects />
					</Route>
					{/* <Route path="/languages">
						<Languages />
					</Route> */}
					<Route path="*">
						<Error />
					</Route>
				</Switch>
			</Suspense>
		</Layout>
	);
}

export default App;
