import React, { useState, useEffect } from "react"
import MobileNavigation from "../navigation/MobileNavigation"
import DesktopNavigation from "../navigation/DesktopNavigation"

const Layout: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [windowDimension, setWindowDimension] = useState(768);

    useEffect(() => {
        setWindowDimension(window.innerWidth);
    }, []);
    useEffect(() => {
        const windowResizeHandler = () => {
            setWindowDimension(window.innerWidth);
        }
        window.addEventListener("resize", windowResizeHandler);
        return () => window.removeEventListener("resize", windowResizeHandler)
    }, [])

    const ActiveNav = () => {
        if (windowDimension < 640) {
            return (<MobileNavigation />)
        } else {
            return (<DesktopNavigation />)
        }
    }

    return (
        <React.Fragment>
            <ActiveNav />
            {children}
        </React.Fragment>
    )
}

export default Layout;
