import React, { useState } from 'react';
import ProjectCard from '../components/projects/ProjectCard';
import project_data from '../data/project-data';
import classes from '../scss/Projects.module.scss';
import MediaViewer from '../components/projects/MediaViewer';
import { useHistory, useParams } from 'react-router-dom';

const Project = () => {

    const { slug } = useParams<{ slug: string }>();
    const history = useHistory();

    const project = project_data.find(item => item.slug === slug)!;

    const [viewer, setViewer] = useState(false);
    const [media, setMedia] = useState();

    if (project === undefined) {
        history.push('/not-found');
    }
    /** @var reverse Reverses ProjectCard container wrap */
    let reverse = false;

    return (
        <>
            {viewer && <MediaViewer media={media!} onClick={setViewer} />}
            {project &&
                <main className={classes.screen}>
                    <div
                        className={classes.projectContainer}
                        key={project.slug}
                        id={project.slug}
                    >
                        <ProjectCard
                            project={project}
                            reverse={reverse = !reverse}
                            onSetViewer={setViewer}
                            onSetMedia={setMedia}
                        />
                    </div>
                </main>
            }
        </>
    )
}

export default React.memo(Project);
