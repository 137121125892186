const NavData = [
    {
        title: 'home',
        path: '/',
    },
    {
        title: 'projects',
        path: '/projects',
    },
    // {
    //     title: 'languages',
    //     path: '/languages',
    // },
]

export default NavData;
