import Language from "./Language";

export default class Project {
    constructor(
        public title: string,
        public slug: string,
        public thumbnail: string,
        public description: string,
        public external_url: string | null,
        public date_created: {},
        public languages: Language[],
        public media?: {
            images?: string[],
            videos?: string[]
        }
    ) {}
}
