import React, { useState } from "react"
import { Link, useLocation } from 'react-router-dom'
import classes from './MobileNavigation.module.scss'
import navigation_data from '../../data/navigation-data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'

const MobileNavigation = () => {

    const location = useLocation();

    const [showNavigation, setShowNavigation] = useState(false);

    function showNavigationHandler() {
        setShowNavigation(!showNavigation);
    }

    return (
        <nav className={`${classes.navBar} ${showNavigation && classes.roundedCorners}`}>
            <div className={classes.navigationIconContainer} onClick={showNavigationHandler}>
                {!showNavigation ? <FontAwesomeIcon icon={faBars} /> : <FontAwesomeIcon icon={faXmark} />}
            </div>
            <div className={`${classes.navListContainer} ${showNavigation ? classes.flex : classes.none}`}>
                <ul className={`${classes.navList}`} id='links' >
                    {navigation_data.map(item => (
                        <Link key={item.title} to={item.path}>
                            <li
                                className={`${classes.navItem} ${location.pathname === item.path && classes.active}`}
                                onClick={showNavigationHandler}>
                                {item.title}
                            </li>
                        </Link>
                    ))}
                </ul>
            </div>
        </nav>
    )
}

export default MobileNavigation;
