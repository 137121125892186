import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import NavigationContextProvider from "./store/navigation-context";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <NavigationContextProvider>
                <App/>
            </NavigationContextProvider>
        </BrowserRouter>
    </React.StrictMode>,
);
