import React, { Dispatch, SetStateAction, useState } from 'react';
import classes from './ProjectCard.module.scss';
import GoToButton from '../UI/GoToButton';
import LanguageIcons from '../UI/LanguageIcons';
import Language from '../../models/Language';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faLink } from '@fortawesome/free-solid-svg-icons';
import ProjectMediaButton from './ProjectMediaButton';
import Project from '../../models/Project';

type Props = {
    project: Project,
    reverse: boolean,
    onSetViewer: Dispatch<SetStateAction<boolean>>,
    onSetMedia: Dispatch<SetStateAction<any>>
}

const ProjectCard: React.FC<Props> = ({ project, reverse, onSetViewer, onSetMedia }) => {

    const [copied, setCopied] = useState(false);

    const icons = project.languages as Language[];
    const date_created = project.date_created as Date;
    const thumbnailExists = () => {
        try {
            return require(`../../images/thumbnails/${project.thumbnail}`);
        } catch (err) {
            return null;
        }
    }

    function showViewerSetMediaHandler(media: Project["media"]) {
        onSetViewer(current => !current);
        onSetMedia(media);
    }

    function copySlugHandler() {
        navigator.clipboard.writeText(`${window.location.href}/${project.slug}`);
        setCopied(true);
    }

    const TextContainer = () => {
        return (
            <div className={`${classes.textContainer} ${classes.cardItem}`}>

                <p className={classes.infoText}>
                    {date_created.toLocaleDateString('us-en', { year: 'numeric', month: '2-digit', day: '2-digit' })}
                </p>

                <button
                    className={classes.title}
                    onClick={() => copySlugHandler()}
                >
                    <h2>
                        {project.title}
                        <FontAwesomeIcon
                            icon={!copied ? faLink : faClipboardCheck}
                            onClick={() => console.log(window.location.href)}
                        />
                    </h2>
                </button>

                <p className={classes.bodyText}>{project.description}</p>


                <div className={classes.iconContainer}>
                    {icons.map(item => (
                        <span key={item.nameLowerCase} /*style={{ color: item.color }}*/>
                            {LanguageIcons[item.nameLowerCase]}
                        </span>
                    ))}
                </div>

                {project.external_url &&
                    <GoToButton href={project.external_url}>
                        <p>Go</p>
                    </GoToButton>
                }

                {project.media?.images &&
                    <ProjectMediaButton
                        type='image'
                        onClick={showViewerSetMediaHandler.bind(this, project.media)}
                    />
                }

                {project.media?.videos &&
                    <ProjectMediaButton
                        type='video'
                        onClick={showViewerSetMediaHandler.bind(this, project.media)}
                    />
                }
            </div>
        )
    }

    const ImageContainer = () => {
        return (
            <>
                {
                    thumbnailExists() ?
                        <div className={`${classes.imageContainer} ${classes.cardItem}`} style={{
                            backgroundImage: `url(${require(`../../images/thumbnails/${project.thumbnail}`)})`,
                            backgroundPosition: 'center center',
                            backgroundSize: 'cover'
                        }}>
                        </div>
                        :
                        <div className={`${classes.mediaContainer} ${classes.cardItem}`}>
                            <h3>Something was supposed to be here, but it is not...</h3>
                            <img src={require('../../images/404.png')} alt="" className={classes.notFoundImage} />
                        </div>
                }
            </>

        )
    }

    return (
        <div className={classes.card}>
            {reverse ?
                <>
                    <TextContainer />
                    <ImageContainer />
                </>
                :
                <>
                    <ImageContainer />
                    <TextContainer /></>
            }
        </div>
    )
}

export default ProjectCard;
