import React from 'react';
import ReactDOM from 'react-dom';
import classes from './Loader.module.scss';
import FullScreen from './FullScreen';

const Spinner = () => {

    return (
        <div className={classes.spinner}></div>
    )
}

const SpinnerContainer = () => {
    return (
        <FullScreen style={{
            backgroundColor: 'rgb(255,255,255)',
            zIndex: '1',
            position: 'fixed',
            height: '100%',
            width: '100%',
            minHeight: '0px'
        }}>
            <Spinner />
        </FullScreen>
    )
}
const Loader = () => {
    return (
        <React.Fragment>
            {ReactDOM.createPortal(<SpinnerContainer />, document.getElementById('loader')!)}
        </React.Fragment>
    )
}

export default Loader;
