import React from 'react';
import ReactDOM from 'react-dom';
import classes from './MediaViewer.module.scss'
import Project from '../../models/Project';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const MediaViewer: React.FC<{
    media: Project['media'],
    onClick: any
}> = ({ media, onClick }) => {

    const Viewer: React.FC = () => {
        return (
            <main className={classes.viewer} onClick={() => onClick()}>
                <div className={classes.buttonContainer}>
                    <button>
                        <FontAwesomeIcon icon={faXmark} onClick={() => onClick()} />
                    </button>
                </div>
                {
                    media?.videos &&
                    <video src={'https://oricode-portfolio-media.s3.us-west-1.amazonaws.com/' + media.videos[0]} controls></video>
                }
            </main>
        )
    }

    return (
        <React.Fragment>
            {ReactDOM.createPortal(<Viewer />, document.getElementById('media')!)}
        </React.Fragment>
    )
}

export default React.memo(MediaViewer);
