import React from 'react';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classes from './ProjectMediaButton.module.scss';

type Props = {
    type: string,
    onClick: any
}

const ProjectMediaButton: React.FC<Props> = ({ type, onClick }) => {

    return (
        <button
            onClick={() => onClick()}
            className={classes.mediaButton}
            type='button'
        >
            <FontAwesomeIcon icon={type === 'image' ? faImage : faVideo} />
        </button>
    )
}

export default React.memo(ProjectMediaButton);
