import React from 'react';
import classes from './FullScreen.module.scss';

type Props = {
    children: React.ReactNode,
    style?: {},
    className?: string
}

const FullScreen: React.FC<Props> = ({ children, style, className }) => {
    return (
        <section className={`${classes.screen} ${className && className}`} style={style}>
            {children}
        </section>
    )
};

export default FullScreen;
