import Project from "../models/Project";
import language_data from "./language-data";

const project_data: Project[] = [
    new Project(
        'Docker To Do',
        'docker-to-do',
        'docker-to-do.png',
        'A dockerized simple checklist app developed in React and Node',
        'https://github.com/oricode-io/docker-to-do',
        new Date('01/16/2024'),
        [
            language_data.docker,
            language_data.reactjs,
            language_data.nodejs,
            language_data.mongodb,
        ]
    ),
    new Project(
        'Slacking',
        'vuejs-slacking',
        'vuejs-slacking.png',
        'A messaging app UI mimic developed in Vue',
        'https://release.d1vnwvvq2s21p9.amplifyapp.com/slacking',
        new Date('03/26/2023'),
        [
            language_data.vuejs,
            language_data.typescript,
            language_data.sass
        ]
    ),
    new Project(
        'Art Gallery',
        'nodejs-art-gallery',
        'nodejs-art-gallery.png',
        'A Node and MongoDB CRUD application',
        'https://oricode-io-nodejs-art-gallery.herokuapp.com/',
        new Date('10/06/2022'),
        [
            language_data.nodejs,
            language_data.mongodb
        ]
    ),
    new Project(
        'Notflix',
        'reactjs-notflix',
        'reactjs-notflix.png',
        'A streaming app developed in React',
        'https://main.d3sfmsalu5ktsk.amplifyapp.com',
        new Date('08/01/2022'),
        [
            language_data.reactjs,
        ]
    ),
    new Project(
        'oricode.io',
        'reactjs-oricode-io',
        'reactjs-oricode-io.png',
        'Portfolio developed in React and Laravel',
        null,
        new Date('08/01/2022'),
        [
            language_data.reactjs,
            language_data.typescript,
            language_data.sass,
            language_data.laravel,
        ]
    ),
    new Project(
        'Rock, Paper, Scissors',
        'reactjs-rps',
        'reactjs-rps.png',
        'Play rock, paper, scissors in React',
        'https://oricode-io-rock-paper-scissors.herokuapp.com/',
        new Date('11/20/2021'),
        [
            language_data.reactjs,
        ]
    ),
    new Project(
        'Reactive Expenses',
        'reactjs-expenses',
        'reactjs-expenses.png',
        'React applet to tally expenses',
        'https://reactjs-expenses.herokuapp.com/',
        new Date('11/12/2021'),
        [
            language_data.reactjs,
        ]
    ),
    new Project(
        'NodeR6',
        'nodejs-r6',
        'nodejs-r6.png',
        'R6Siege-themed blog developed in Node and MongoDB',
        'https://evening-forest-03188.herokuapp.com/',
        new Date('07/17/2021'),
        [
            language_data.nodejs,
            language_data.mongodb,
        ]
    ),
    new Project(
        'JS Webcam',
        'js-webcam',
        'js-webcam.png',
        'Activate your webcam with a simple JS program',
        'https://oricode-io-js-webcam.herokuapp.com/index.html',
        new Date('08/18/2021'),
        [
            language_data.javascript,
            language_data.html5,
            language_data.css3
        ]
    ),
    new Project(
        'JS Video Player',
        'js-video-player',
        'js-video-player.png',
        'Custom video player in vanilla JS and HTML5',
        'https://oricode-io-js-video-player.herokuapp.com/',
        new Date('07/17/2021'),
        [
            language_data.javascript,
            language_data.html5,
            language_data.css3
        ]
    ),
    new Project(
        'Tic tac toe',
        'python-ttt',
        'python-ttt.png',
        'Terminal-based tic-tac-toe game developed in Python',
        null,
        new Date('06/23/2021'),
        [
            language_data.python,
        ],
        {
            videos: ['python_tic_tac_toe.mp4']
        }
    ),
    new Project(
        'SQL Slots',
        'sql-slots',
        'sql-slots.png',
        'Slot machine game developed in MSSQL',
        null,
        new Date('06/10/2021'),
        [
            language_data.mssql,
        ],
        {
            videos: ['sql_slots.mp4']
        }
    ),
    new Project(
        'Python Slots',
        'python-slots',
        'python-slots.png',
        'Console-based slots game developed in Python',
        null,
        new Date('05/19/2021'),
        [
            language_data.python,
        ],
        {
            videos: ['python_slots.mp4']
        }
    ),
    new Project(
        'Progress Bar',
        'js-progress-bar',
        'js-progress-bar.png',
        'Create a progress bar on scroll',
        'https://oricode-io-js-progress-bar.herokuapp.com/',
        new Date('11/15/2020'),
        [
            language_data.javascript,
            language_data.css3,
        ]
    ),
    new Project(
        'Geolocation',
        'js-geolocation',
        'js-geolocation.png',
        'Ping your location with the help of Mapbox and Leaflet API\'s',
        'https://oricode-io-js-geolocation.herokuapp.com/',
        new Date('11/05/2020'),
        [
            language_data.javascript
        ]
    ),
    new Project(
        'Greedy Algorithm',
        'greedy-algorithm',
        'js-greedy-algorithm.png',
        'Enter change due and see the minimum coin count possible',
        'https://oricode-io-greedy-js.herokuapp.com/',
        new Date('10/30/2020'),
        [
            language_data.javascript,
        ]
    ),
    new Project(
        'Ripley\'s Adventures',
        'php-alien',
        'php-alien.png',
        'Alien-themed CMS blog developed in vanilla PHP and MySQL',
        null,
        new Date('05/26/2020'),
        [
            language_data.php,
            language_data.mysql,
            language_data.html5,
            language_data.css3
        ]
    ),
    new Project(
        'Move',
        'html5-move',
        'html5-move.png',
        'Vanilla HTML and CSS project for a exercise-themed site',
        'https://oricode-io-html-exercise.herokuapp.com/index.html',
        new Date('03/29/2017'),
        [
            language_data.html5,
            language_data.css3
        ]
    ),
    new Project(
        'Coffee House',
        'html5-coffee-house',
        'html5-coffee-house.png',
        'Vanilla HTML and CSS project for a coffee shop',
        'https://oricode-io-html-coffee-house.herokuapp.com/index.html',
        new Date('03/29/2017'),
        [
            language_data.html5,
            language_data.css3
        ]
    ),
]

export default project_data;
