import Language from "../models/Language";
import language_code from "./language-code";

const language_data: {
    javascript: Language,
    typescript: Language,
    reactjs: Language,
    reactnative: Language,
    nodejs: Language,
    vuejs: Language,
    laravel: Language,
    php: Language,
    mysql: Language,
    mssql: Language,
    mongodb: Language,
    python: Language,
    html5: Language,
    css3: Language,
    sass: Language,
    docker: Language,
} = {
    javascript: new Language(
        'JavaScript',
        'javascript',
        '#fcdc00',
        'javascript.png',
        {
            text: language_code.jsCode,
            theme: 'pojoaque',
            language: 'javascript',
        }
    ),
    typescript: new Language(
        'TypeScript',
        'typescript',
        '#007acc',
        'typescript.png',
        {
            text: language_code.tsCode,
            theme: 'vs2015',
            language: 'typescript',
        }
    ),
    reactjs: new Language(
        'React.js',
        'reactjs',
        '#087ea4',
        'reactjs.png',
        {
            text: language_code.reactJSCode,
            theme: 'nord',
            language: 'jsx',
        }
    ),
    reactnative: new Language(
        'React Native',
        'reactnative',
        '#61dafb',
        'reactnative.png',
        {
            text: language_code.rnCode,
            theme: 'obsidian',
            language: 'jsx',
        }
    ),
    nodejs: new Language('Node.js',
        'nodejs',
        '#8cc84c',
        'nodejs.png',
        {
            text: language_code.nodeCode,
            theme: 'obsidian',
            language: 'javascript',
        }
    ),
    vuejs: new Language(
        'Vue.js',
        'vuejs',
        '#42b883',
        'vuejs.png',
        {
            text: language_code.vueCode,
            theme: 'dracula',
            language: 'html',
        }
    ),
    laravel: new Language(
        'Laravel',
        'laravel',
        '#f9322c',
        'laravel.png',
        {
            text: language_code.laravelCode,
            theme: 'monokaiSublime',
            language: 'php',
        }
    ),
    php: new Language('PHP',
        'php',
        '#4f5b93',
        'php.png',
        {
            text: language_code.phpCode,
            theme: 'monoBlue',
            language: 'php',
        }
    ),
    mysql: new Language('MySQL',
        'mysql',
        '#0074a3',
        'mysql.png',
        {
            text: language_code.mysqlCode,
            theme: 'androidstudio',
            language: 'sql',
        }
    ),
    mssql: new Language('MSSQL',
        'mssql',
        '#0078d4',
        'mssql.png',
        {
            text: language_code.mysqlCode,
            theme: 'vs2015',
            language: 'sql',
        }
    ),
    mongodb: new Language('MongoDB',
        'mongodb',
        '#023430',
        'mongodb.png',
        {
            text: `model.findOne({})`,
            theme: 'codepen',
            language: 'javascript',
        }
    ),
    python: new Language('Python',
        'python',
        '#2b5b84',
        'python.png',
        {
            text: language_code.pythonCode,
            theme: 'hybrid',
            language: 'python',
        }
    ),
    html5: new Language('HTML5',
        'html5',
        '#ef642c',
        'html5.png',
        {
            text: language_code.sassCode,
            theme: 'ocean',
            language: 'html',
        }
    ),
    css3: new Language('CSS3',
        'css3',
        '#019ae5',
        'css3.png',
        {
            text: language_code.sassCode,
            theme: 'ocean',
            language: 'html',
        }
    ),
    sass: new Language('Sass',
        'sass',
        '#bf4080',
        'sass.png',
        {
            text: language_code.sassCode,
            theme: 'ocean',
            language: 'html',
        }
    ),
    docker: new Language('Docker',
        'docker',
        '#0db7ed',
        'docker.png',
    ),
}

export default language_data;
