import React from 'react';
import classes from './GoToButton.module.scss';
import { Link } from 'react-router-dom';

type Props = {
    href: string,
    title?: string
    style?: {},
    children?: React.ReactNode
}

const GoToButton: React.FC<Props> = ({ href, style, title, children }) => {
    return (
        <a
            href={href}
            target="_blank"
            rel="noreferrer"
            className={classes.button} style={style}
        >
            {title && <p>{title}</p>}
            {children && children}
        </a>
    )
}

export default GoToButton;
