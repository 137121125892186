const jsCode =
    `function naiveStringSearch(haystack, needle) {
    let matches = 0;
    for (let i = 0; i < haystack.length; i++) {
        if (haystack[i] === needle[0]) {
            for (let j = 0; j < needle.length; j++) {
                if (haystack[j + i] === needle[j]) {
                    if (j === needle.length - 1) matches++;
                } else {
                    break;
                }
            }
        }
    }
    return matches;
}`;

const tsCode =
    `class Human () {
    id = new Id();
    constructor(
        public firstName: string,
        public lastName: string,
        public age: number
    ), {}
}`;

const reactJSCode =
    `import React from 'react';
import classes from "./BubbleButton.module.scss";

interface Props {
  onClick: () => {};
  children: React.ReactNode;
};

const BubbleButton: React.FC<Props> = ({
  onClick,
  children,
}) => {
  return (
    <button 
        onClick={onClick} 
        type="button" 
        className={classes.bubbleButton}
    >
      {children}
    </button>
  );
};

export default React.memo(BubbleButton);
`;

const rnCode =
    `const LanguageCodeBlock: React.FC<Props> = ({ text, theme, language }) => {
    return (
        <CodeBlock
            className={classes.container}
            text={text}
            theme={themes[theme]}
            language={language}
        />
    )
}`;

const nodeCode =
    `const express = require('express');
const router = express.router();
const Human = require('../models/Human');

router.get('/api/humans', async (req, res, next) => {
    const allHumans = await Human.getAll();
    return allHumans;
});

router.get('/api/human/:humanId', async (req, res, next) => {
    const human = await Human.findOne(req.params.humanId);
    return human;
})

module.exports = router;`;

const vueCode =
    `<template>
    <button @click="onClick">
        <slots></slots>
    </button>
</template>

<script lang='ts'>
import {defineComponent} from 'vue';

export default defineComponent({
    props: ["onClick"]
})
</script>

<style scoped lang="scss">
@import '../scss/buttonStyles';
@import '../scss/variables';

button {
    @include bubbleButton;
    background-color: $vueGreen;
}
</style>
`;

const laravelCode =
    `public function LanguageCodeBlock() 
{
    $response = Response::get();
}
`;

const phpCode =
    `public function LanguageCodeBlock() 
{
    $response = Response::get();
}
`;

const pythonCode =
    `def main()
    print('hello')
`;

const sassCode = 
`@mixin customButton {
    background-color: #bf4080;
}`;
const mssql = 
`
`;

const mysqlCode =
`/* Most popular last minute Christmas purchase */
SELECT p.name, COUNT(*)
FROM products AS p
    JOIN order_item AS oi ON p.id = oi.product_id
    JOIN orders AS o ON o.id = oi.order_id
    JOIN customers as c ON c.id = c.customer_id
WHERE o.order_date = '2022-12-30'
ORDER BY COUNT(*) DESC;
`;

const language_code = {
    jsCode,
    tsCode,
    reactJSCode,
    rnCode,
    nodeCode,
    vueCode,
    laravelCode,
    phpCode,
    pythonCode,
    sassCode,
    mssql,
    mysqlCode
}

export default language_code;
