import { Context, createContext, ReactNode, useState } from 'react';
import { ViewTypes } from "../pages/Projects";

interface NavigationContextInterface {
    projectViewType: ViewTypes['type'],
    setProjectViewTypeHandler: (viewType: ViewTypes['type']) => void,
}

// @ts-ignore
export const NavigationContext: Context<NavigationContextInterface> = createContext({
    projectViewType: 'tiles',
    setProjectViewTypeHandler: (viewType: ViewTypes['type']) => {}
});

export default function NavigationContextProvider({children}: { children: ReactNode }) {
    const [viewType, setViewType] = useState<ViewTypes['type']>('tiles');

    const setProjectViewTypeHandler = (viewType: ViewTypes['type']) => {
        setViewType(viewType);
    }

    return (
        <NavigationContext.Provider value={{
            projectViewType: viewType,
            setProjectViewTypeHandler: setProjectViewTypeHandler,
        }}>
            {children}
        </NavigationContext.Provider>
    )
}
